import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
  [key: string]: MessageDescriptor;
}

const messages: Messages = defineMessages({
  password: {
    id: 'cboard.components.ChangePassword.password',
    defaultMessage: 'New Password',
  },
  passwordRepeat: {
    id: 'cboard.components.ChangePassword.passwordRepeat',
    defaultMessage: 'Repeat New Password',
  },
  send: {
    id: 'cboard.components.ChangePassword.send',
    defaultMessage: 'Send',
  },
  changePassword: {
    id: 'cboard.components.ChangePassword.changePassword',
    defaultMessage: 'Save your new password',
  },
  changePasswordText: {
    id: 'cboard.components.ChangePassword.changePasswordText',
    defaultMessage: 'Enter two times your new password to access to Komunicare.',
  },
  changePasswordSuccess: {
    id: 'cboard.components.ChangePassword.changePasswordSuccess',
    defaultMessage: 'Success!! Your password was updated.',
  },
  redirect: {
    id: 'cboard.components.ChangePassword.redirect',
    defaultMessage: 'Redirecting you to the login page...',
  },
});

export default messages;
