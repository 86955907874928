import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
  [key: string]: MessageDescriptor;
}

const messages: Messages = defineMessages({
  heading: {
    id: 'cboard.components.AuthScreenInformation.heading',
    defaultMessage: 'Komunicare',
  },
  text: {
    id: 'cboard.components.AuthScreenInformation.text',
    defaultMessage: 'Sign up to sync your settings!',
  },
});

export default messages;
