import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
  [key: string]: MessageDescriptor;
}

const messages: Messages = defineMessages({
  save: {
    id: 'cboard.components.FormDialog.save',
    defaultMessage: 'Save',
  },
  cancel: {
    id: 'cboard.components.FormDialog.cancel',
    defaultMessage: 'Cancel',
  },
});

export default messages;
