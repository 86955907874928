import { defineMessages, MessageDescriptor } from 'react-intl';

interface Messages {
  [key: string]: MessageDescriptor;
}

const messages: Messages = defineMessages({
  analytics: {
    id: 'cboard.components.Analytics.analytics',
    defaultMessage: 'Analytics',
  },
  mostUsedBoards: {
    id: 'cboard.components.Analytics.mostUsedBoards',
    defaultMessage: 'Most Used Boards',
  },
  topUsedButtons: {
    id: 'cboard.components.Analytics.topUsedButtons',
    defaultMessage: 'Top Used Buttons',
  },
  symbolSources: {
    id: 'cboard.components.Analytics.symbolSources',
    defaultMessage: 'Symbol Sources',
  },
  tenDaysUsage: {
    id: 'cboard.components.Analytics.tenDaysUsage',
    defaultMessage: 'Ten Days Usage',
  },
  twentyDaysUsage: {
    id: 'cboard.components.Analytics.twentyDaysUsage',
    defaultMessage: 'Twenty Days Usage',
  },
  thirtyDaysUsage: {
    id: 'cboard.components.Analytics.thirtyDaysUsage',
    defaultMessage: 'Thirty Days Usage',
  },
  sixtyDaysUsage: {
    id: 'cboard.components.Analytics.sixtyDaysUsage',
    defaultMessage: 'Sixty Days Usage',
  },
  close: {
    id: 'cboard.components.Analytics.close',
    defaultMessage: 'Close',
  },
  loadingError: {
    id: 'cboard.components.Analytics.loadingError',
    defaultMessage: 'There was an error loading your data. Please refresh.',
  },
  totalWords: {
    id: 'cboard.components.Analytics.totalWords',
    defaultMessage: 'Total Words',
  },
  totalPhrases: {
    id: 'cboard.components.Analytics.totalPhrases',
    defaultMessage: 'Total phrases',
  },
  boardsUsed: {
    id: 'cboard.components.Analytics.boardsUsed',
    defaultMessage: 'Boards used',
  },
  tilesEdited: {
    id: 'cboard.components.Analytics.tilesEdited',
    defaultMessage: 'Tiles edited',
  },
  editingEvents: {
    id: 'cboard.components.Analytics.editingEvents',
    defaultMessage: 'Editing Events',
  },
  speechEvents: {
    id: 'cboard.components.Analytics.speechEvents',
    defaultMessage: 'Speech Events',
  },
  navigationEvents: {
    id: 'cboard.components.Analytics.navigationEvents',
    defaultMessage: 'Navigation Events',
  },
  name: {
    id: 'cboard.components.Analytics.name',
    defaultMessage: 'Name',
  },
  timesClicked: {
    id: 'cboard.components.Analytics.timesClicked',
    defaultMessage: 'Times Clicked',
  },
  action: {
    id: 'cboard.components.Analytics.action',
    defaultMessage: 'Action',
  },
});

export default messages;
